<template>
  <div>
    <d-body-top-bar :title="$t('views.bookings.your-bookings')">
      <div slot="tree">
        <label class="bg-doinsport-primary ml-1">
          {{ $t('general.actions.list') }}
        </label>
      </div>
    </d-body-top-bar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 all-bookings">
          <b-card class="all-bookings-card">
            <b-row class="row-style" no-gutters>
              <b-col class="mt-2 ml-3" :cols="getTopNavMenuCol()">
                <d-body-nav-bar
                  :tabs="$store.getters['menu/getTabs'].bookingsIndex"
                  :updated-tab="updatedTab"
                  parent="bookings"
                  @tab-changed="onTabChanged"
                />
              </b-col>
              <b-col
                align="right"
                class="mt-3 mr-3"
              >
                <export-pdf
                  :btn="btnAttrs"
                  target="formulas"
                />
              </b-col>
              <b-col
                align="right"
                class="mt-3 mr-3"
              >
                <export-csv
                  target="bookings"
                  label="components.custom.export-csv.export-all-bookings"
                />
              </b-col>
              <b-col class="mt-3">
                <div class="has-search">
                  <span class="fa fa-search form-control-feedback"></span>
                  <input
                    type="text"
                    class="form-control"
                    v-model="filter"
                    @keyup="manualFilter"
                    :placeholder="$t('components.doinsport.table.search')"
                  >
                </div>
              </b-col>
            </b-row>
            <d-table
              :tablefields="tableFields"
              :items="items"
              :is-busy="isBusy"
              :totalRows="totalRows"
              :per-page="itemsPerPage"
              :per-page-enabled="true"
              @on:sort-by:item="onSort"
              @filter:from-server="search"
              @per-page:update="onPerPageUpdate"
              @on:data-table-btn:show="showBooking"
              @current-page:update="onCurrentPageUpdate"
            >
            </d-table>
            <booking-details
              :v-if="selectedBookingId && blockPrices.length > 0"
              :display="display"
              :booking-id="selectedBookingId"
              position="right"
              @hidden="onHide"
            />
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {hydrate} from "@/utils/form";
import ExportCsv from "@custom/ExportCsv";
import Booking from "@/classes/doinsport/Booking";
import {getClubBookings} from "@/api/doinsport/services/bookings/booking.api";
import {getPrices} from "@api/doinsport/services/timetable/blocks/price/price.api";
import ExportPdf from "@custom/ExportPdf";

export default {
  data: () => ({
    passed: false,
    display: false,
    visible: false,
    lastOrder: null,
    selectedBooking: null,
    selectedPlayground: null,
    selectedBookingId: null,
    updatedTab: false,
    isBusy: true,
    commingSoon: true,
    canceled: false,
    items: [],
    itemsPerPage: 10,
    currentPage: 1,
    totalRows: 1,
    hide: true,
    show: true,
    isCanceled: false,
    operator: 'after',
    date: null,
    bookingsList: [],
    filter: ''
  }),
  components: {
    ExportPdf,
    ExportCsv,
    BookingDetails: () => import('@/views/planning/schedule/playgrounds/bookings/details/Index'),
  },
  created() {
    this.date = this.$moment.utc().format('YYYY-MM-DD');
    this.loadBlockPrices();
  },
  computed: {
    blockPrices() {
      return this.$store.getters["playgrounds/getSelectedBlockPrices"];
    },
    btnAttrs() {
      return {
        label: this.$store.getters['layout/getInnerWidth'] >= 991 ? 'components.custom.export-csv.export-all-formula-bookings' : '',
        icon: 'fa fa-external-link mr-2 mt-1',
        class: 'd-btn btn d-btn-default font-text-title',
      }
    },
    tableFields() {
      return [
        {key: "name", label: this.$t("views.bookings.index.bookingName"), sortable: true},
        {key: "startAt", label: this.$t("views.bookings.index.date"), sortable: true},
        {key: "duration", label: this.$t("views.bookings.index.duration"), sortable: true},
        {key: "activity", label: this.$t("views.bookings.index.activity"), sortable: true},
        {key: "bookingPlaygrounds", label: this.$t("views.bookings.index.playground"), sortable: true},
        {key: "bookingCategory", label: this.$t("views.bookings.index.category"), sortable: true},
        {key: "recurringReservation", label: this.$t("views.bookings.index.recurringReservation")},
        {key: "bookingStatus", label: this.$t("views.bookings.index.onlinePayment")},
        {key: "reservedBy", label: this.$t("views.bookings.index.reservedBy"), sortable: true},
        {key: "createdAt", label: this.$t("views.bookings.index.reservationDate"), sortable: true},
        {key: "actions", label: 'Action'},
      ];
    }
  },
  methods: {
    loadBookings() {
      let lastSelectedTab = JSON.parse(localStorage.getItem('bookings-selectedTab'));

      if (this.$router.prevRoute.name === 'dashboard') {
        const prevSelectedTab = JSON.parse(localStorage.getItem('bookingsActivity-selectedTab'));

        if (prevSelectedTab !== null) {
          lastSelectedTab = prevSelectedTab;
        }
      }

      this.onTabChanged({name: lastSelectedTab});
    },
    onSort(item) {
      if (item.sortBy !== '') {
        this.lastOrder = item;
        this.getBookingsList(this.itemsPerPage, this.currentPage, this.filter, this.canceled, this.date, this.operator);
      }
    },
    getFilters() {
      if (this.lastOrder) {
        let filter = null;
        const order = this.lastOrder.sortDesc ? 'desc' : 'asc';

        switch (this.lastOrder.sortBy) {
          case 'activity':
            filter = 'activity.id';
            break;
          case 'duration':
            filter = 'timetableBlockPrice.duration';
            break;
          case 'bookingPlaygrounds':
            filter = 'playgrounds.id';
            break;
          case 'bookingCategory':
            filter = 'category.id';
            break;
          case 'reservedBy':
            filter = 'userClient.id';
            break;
          default:
            filter = this.lastOrder.sortBy;
        }

        if (filter) {
          return '&order[' + filter + ']=' + order;
        }
      }

      return '';
    },
    closeModalReload() {
      this.hide = !this.hide;
      this.selectedBooking = null;
      this.selectedPlayground = null;

      let lastSelectedTab = JSON.parse(localStorage.getItem('bookings-selectedTab'));
      this.onTabChanged({name: lastSelectedTab});
    },
    showBooking(scope) {
      this.selectedBookingId = scope.id;

      this.$nextTick(() => {
        setTimeout(() => {
          document.body.setAttribute('style', 'overflow-y:hidden;');
          this.display = true;
        }, 50)
      });
    },
    onHide(booking) {
      document.body.setAttribute('style', 'overflow-y:scroll;');
      this.display = false;

      if (booking.name) {
        for (const i in this.items) {
          if (this.items[i].id === this.selectedBookingId) {
            hydrate(this.items[i], booking);
          }
        }
      }

      this.selectedBookingId = null;
    },
    loadPassedBookings() {
      this.date = this.$moment.utc().format('YYYY-MM-DD');
      this.operator = 'strictly_before';
      this.canceled = false;
      this.getBookingsList(this.itemsPerPage, this.currentPage, '', false, this.date, this.operator);
    },
    loadCanceledBookings() {
      this.date = '';
      this.operator = '';
      this.canceled = true;

      this.getBookingsList(this.itemsPerPage, this.currentPage, '', true, this.date, this.operator);
    },
    loadComingSoonBookings() {
      this.date = this.$moment.utc().format('YYYY-MM-DD');
      this.operator = 'after';
      this.canceled = false;

      this.getBookingsList(this.itemsPerPage, this.currentPage, '', false, this.date, this.operator);
    },
    onTabChanged(tab) {
      localStorage.setItem('bookings-selectedTab', JSON.stringify(tab.name));

      this.updatedTab = !this.updatedTab;
      this.loadSelectedTabBooking(tab.name);
    },
    loadSelectedTabBooking(selectedTab) {
      switch (selectedTab) {
        case "views.dashboard.content.activities.bookings.passed": {
          this.loadPassedBookings();
          return;
        }
        case "views.dashboard.content.activities.bookings.canceled": {
          this.loadCanceledBookings();
          return;
        }
        default: {
          this.loadComingSoonBookings();
        }
      }
    },
    getColsClasses(button) {
      const width = this.$store.getters['layout/getInnerWidth'];
      if (width < 925 && button === 'export') {
        return '';
      }
      if (width < 1326 && width >= 924) {
        return '';
      } else {
        return 'mt-2';
      }
    },
    getTopNavMenuCol() {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width < 1326) {
        return '12';
      } else {
        return '6';
      }
    },
    getBtnsCols(button) {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width < 925 && button === 'search') {
        return '12';
      }

      if (width < 1326 && width >= 924) {
        return '4';
      } else {
        return '6';
      }
    },
    getBookingsList(itemsPerPage, currentPage, search, canceled, date, operator) {
      this.isBusy = true;
      const extraFilters = this.getFilters();

      getClubBookings(itemsPerPage, currentPage, search, canceled, date, operator, extraFilters)
        .then((response) => {
          this.totalRows = response.data['hydra:totalItems'];
          this.currentPage = currentPage;
          this.itemsPerPage = itemsPerPage;
          this.items = []
          for (let item of response.data['hydra:member']) {
            Object.assign(item, {actions: ['show']});
            const booking = new Booking(item);
            this.items.push(booking);
          }
        })
        .finally(() => {
          this.isBusy = false;
        })
      ;
    },
    onCurrentPageUpdate(page) {
      this.getBookingsList(this.itemsPerPage, page, '', this.canceled, this.date, this.operator);
    },
    onPerPageUpdate(itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      this.currentPage = 1;
      this.getBookingsList(itemsPerPage, this.currentPage, '', this.canceled, this.date, this.operator);
    },
    search() {
      this.getBookingsList(this.itemsPerPage, this.currentPage, this.filter, this.canceled, this.date, this.operator);
    },
    manualFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.search();
      }, 400);
    },
    loadBlockPrices() {
      getPrices(500, 1, '')
        .then((response) => {
          this.$store.dispatch('playgrounds/saveBlockPricesSelection', response.data['hydra:member']);
        })
      ;
    },
  },
  mounted() {
    this.loadBookings();
  },
};
</script>
<style lang="scss" scoped>
@import "@lazy/_b-card.scss";
</style>
